<template>
  <form action="" @submit.stop.prevent="() => {}">
    <div class="form-row desktop_form" v-if="type === 'tabs'">
      <div class="form-group col-md-3">
        <select-theme1 v-if="!isFCLAgent"
          @changeDestination="destChanged"
          @optionSelect="destSelect"
          :list="destinationList"
        />
        <select-destinations v-else
          @changeDestination="changeDestinations"
          :type="type"
          :data="dataDestination"
          :theme='theme1'
        />
      </div>

      <div class="form-group col-md-3">
        <hotel-theme1
          :destination="destinationCityName"
          :hotelListShow="destinationChanged"
          @changeHotel="hotelChanged"
          @optionSelect="hotelSelect"
          :list="hotelList"
        />
      </div>

      <div class="form-group col-md-3">
        <datepicker-range1
          :calendarShow="destinationChanged && hotelListChanged"
          @finalDateSelect="finalDateSelect"
          @dates="chooseDate"
        ></datepicker-range1>
      </div>
      <div class="form-group col-md-2">
        <occupancy-pax @changeBodyCount="changeCount" :dropdownShow="dropdownShow"/>
      </div>
      <div class="form-group col-md-1 d-flex">
        <button class="btn flex-fill" @click="submitSearch" :disabled="searchDisable" style="padding:2px;">
          {{ $t("search-tab.search") }}
          <b-spinner small v-if="isLoading"></b-spinner>
          <i class="fas fa-search mr-2" v-else></i>
        </button>
      </div>
    </div>

    <!-- <MobileSearchForm/> -->
    <div class="card card-body boxSideSearch border-0" :class="{ 'mobile_form': type === 'tabs', 'side_form': type === 'side' }">
      <div class="form-row pt-2">
        <div class="form-group col-12">
          <span>{{
            $t("search-tab.mobile-form.destination-label")
          }}</span>
          <select-theme1 v-if="!isFCLAgent"
          @changeDestination="destChanged"
          @optionSelect="destSelect"
          :list="destinationList"
        />
        <select-destinations v-else
          @changeDestination="changeDestinations"
          :type="type"
          :data="dataDestination"
          :theme='theme1'
        />
        </div>

        <div class="form-group col-12">
          <label>{{
            $t("search-tab.mobile-form.hotel-label")
          }}</label>
          <hotel-theme1
            :destination="destinationCityName"
            :hotelListShow="destinationChanged"
            @changeHotel="hotelChanged"
            @optionSelect="hotelSelect"
            :list="hotelList"
            :multiple="true"
          />
        </div>

        <div class="form-group col-12">
          <label>{{ $t("search-tab.mobile-form.start-date") }}</label>
          <div class="input-group">
            <datepicker-range1
              :calendarShow="destinationChanged && hotelListChanged"
              @dates="chooseDate"
              type="side"
            ></datepicker-range1>
          </div>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countAdult">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.adult")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countChild">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.child")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countInfant">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.infant")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group d-flex">
          <button
            class="btn flex-fill btn-outline-dark btn-search"
            @click="submitSearch"
            :disabled="searchDisable"
          >
            {{ $t("search-tab.search") }}
            <b-spinner v-if="isLoading" small></b-spinner>
            <i class="fas fa-search mr-2" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
  BSpinner,
} from 'bootstrap-vue';
import TemplateSearchTab from './TemplateSearchTab';

export default {
  name: 'SearchTabTheme1',
  mixins: [TemplateSearchTab],
  components: {
    OccupancyPax: () => import('@/components/atoms/occupancyPax/OccupancyPaxTheme1'),
    SelectTheme1: () => import('@/components/atoms/searchSelect/SearchSelectTheme1'),
    SelectDestinations: () => import('@/components/atoms/SelectDestinations'),
    HotelTheme1: () => import('@/components/atoms/multipleSelect/MultipleSelectTheme1'),
    DatepickerRange1: () => import('@/components/atoms/customDatepickerRange/CustomDatepickerRangeTheme1'),
    // Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    BFormSelect,
    BFormSelectOption,
    BSpinner,
  },
};
</script>

<style scoped>
.card.boxSideSearch{
  background-color: #fff;
}
.card.boxSideSearch .form-group label{
  color: #333;
}
.card.boxSideSearch.side_form .form-group label{
  color: #fff;
}
.card.boxSideSearch .form-group select{
  border-radius: 5px;
}
.card.boxSideSearch .btn-search {
  background-color: #23BEDC;
  box-shadow: 0px 3px 6px #23BEDC;
  color: #fff;
  width: 100%;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.card.boxSideSearch.side_form .btn-search {
  box-shadow: 0 3px 10px rgba(0,0,0,.1);
  background-color: #fff;
  color: #23bedc;
  font-size: 15px;
  font-weight: 400;
  padding: 12px 18px;
  border-radius: 6px;
  border: none;
  margin-top: 10px;
}
.card.boxSideSearch.side_form .btn.btn-search i {
  color: #23bedc;
}
.card.boxSideSearch .btn.btn-search i {
  font-size: 20px;
  margin-right: 10px;
  color: #fff;
}
.card.boxSideSearch .btn.btn-search i:hover,
.card.boxSideSearch .btn.btn-search i:focus,
.card.boxSideSearch .btn.btn-search:hover i {
  color: #fff;
}
.custom-control {
  padding-right: 1rem;
}
.boxSideSearch.side_form {
  background-color: #23BEDC;
  border-radius: 8px;
  margin-top: 24px;
}
.card.boxSideSearch.side_form .btn.btn-search:hover {
    color: #fff;
    background-color: transparent;
    justify-content: space-between;
    border-color: #fff;
    display: flex;
}
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
  .boxSideSearch .card.boxSideSearch {
    background-color: unset;
  }
  .boxSideSearch .filter-tab form select {
    border-radius: 5px;
  }
  .boxSideSearch .serach_tabbody .form-group > label {
    display: none !important;
  }
}
</style>

<style>
.filter-tab form button.btn {
  background-color: #23BEDC;
  color: #fff;
  border-radius: 7px;
  border-color: unset;
}
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
.boxSideSearch .filter-tab form button.btn {
  border-radius: 5px;
  background-color: #23BEDC;
  border-color: #23BEDC;
}
.boxSideSearch.side_form .vs__dropdown-toggle,
.boxSideSearch.side_form .multiselect__tags,
.boxSideSearch.side_form .multiselect__single,
.boxSideSearch.side_form .input-group-text,
.boxSideSearch.side_form .form-control{
  background-color: transparent !important;
  border-color: #fff !important;
}
.boxSideSearch.side_form .vs__dropdown-toggle,
.boxSideSearch.side_form .vs__selected,
.boxSideSearch.side_form .multiselect__tags,
.boxSideSearch.side_form .multiselect__single,
.boxSideSearch.side_form .input-group-text,
.boxSideSearch.side_form input.form-control,
.boxSideSearch.side_form select.form-control{
  color: #fff !important;
}
.boxSideSearch.side_form select.form-control option{
  color: #495057;
}
.boxSideSearch.side_form .vs__open-indicator {
  fill: #fff;
}
.boxSideSearch.side_form .multiselect__select:before {
  border-color: #fff transparent transparent transparent;
}
.boxSideSearch.side_form .custom-select {
  background: #fff;
}
</style>
