import { mapGetters } from 'vuex';

export default {
  name: 'SearchTabTemplate',
  props: {
    breadcrumb: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'tabs',
    },
  },
  data() {
    return {
      destination: '',
      destinations: [],
      selectedHotels: [],
      hotelList: [],
      fromDate: [],
      toDate: [],
      destinationChanged: false,
      hotelListChanged: false,
      dropdownShow: false,
      hotelName: '',
      destinationText: '',

      supplierListForSearch: process.env.VUE_APP_SUPPLIER_SEARCH_LIST.split(','),
    };
  },
  mounted() {
    const thisInstance = this;
    this.$root.$on('callBreadcrumbEvent', () => {
      thisInstance.callBreadcrumb();
    });
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_DOMESTIC_DESTINATION',
      totalHotelList: 'GET_DOMESTIC_HOTELS',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      marketerId: 'GET_MARKETER_ID',
      partnerId: 'GET_PARTNER_ID',
      hotelAllInfo: 'GET_HOTEL_ALL_INFO',
      // isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isMultipleSearchCase: 'GET_IS_MULTIPLE_SEARCH_CASE',
      isMultipleDestinationSearch: 'GET_MULTI_SEARCH_DESTINATION_STATE',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    cityId() {
      return this.query.city;
    },
    hotelCode() {
      return this.query.hotelCode;
    },
    linkDataSearch() {
      return {
        dest: this.destinationCityName,
      };
    },
    searchDisable() {
      return (this.destinations.length === 0 && this.destination === '')
       || this.fromDate.length === 0 || this.toDate.length === 0 || this.fromDate.length !== this.toDate.length
       || !this.countAdult
       || this.selectedHotels.length === 0;
    },
    countAdult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult ? this.$store.getters.GET_SEARCH_CONTENT.adult : 2;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    countChild: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child ? this.$store.getters.GET_SEARCH_CONTENT.child : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    countInfant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant ? this.$store.getters.GET_SEARCH_CONTENT.infant : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
    destinationCityName() {
      let filterArray = [];
      if (this.isMultipleSearchCase) {
        filterArray = this.destinationList.filter((item) => this.destinations.includes(item.code));
        // filterArray = this.destinationList.filter((item) => item.code === this.destination);
      } else {
        filterArray = this.destinationList.filter((item) => item.code === this.destination);
      }
      const cityNames = filterArray.length > 0 ? filterArray.reduce((prev, curr) => `${prev} ${filterArray.length > 1 ? ',' : ''} ${curr.name}`, '') : '';
      return cityNames;
    },
    destinationName() {
      return this.destination;
    },
    getBreadcrumb() {
      const landingUrl = this.$route.fullPath;
      let hotelName = '';
      if (this.hotelAllInfo.length && this.hotelCode) {
        const hotelInfo = this.hotelAllInfo;
        const hotel = hotelInfo.find((elm) => elm.hotelConversion?.hotelExternalCode === this.hotelCode);
        hotelName = hotel?.hotelConversion?.hotelName || '';
      }

      let destCode = '';
      let destSnd = '';
      let destThird = '';
      let destinationText = '';
      switch (this.$route.name) {
        case 'search-result': {
          const destCodes = this.$route.query.city.split('');
          const filterArray = this.destinationList.filter((item) => destCodes.includes(item.code));
          destinationText = filterArray.length > 0 ? filterArray.reduce((prev, curr) => `${prev}, ${curr.name}`, '') : '';
          destSnd = this.$t('hotel-page.hotel-breadcrumb-hotel-sec', { destinationName: destinationText });
          break;
        }
        case 'hotels-page': {
          if (hotelName === '' || hotelName === undefined) {
            destCode = landingUrl.split('=')[1];
            const filterArray = this.destinationList.filter((item) => item.code === destCode);
            destSnd = filterArray.length > 0 ? filterArray[0].name : '';
            destThird = this.$t('hotel-page.hotel-in-dest', { destinationName: destSnd });
          } else {
            destCode = landingUrl.split('=')[1];
            destCode = destCode.split('&')[0];
            const filterArray = this.destinationList.filter((item) => item.code === destCode);
            destinationText = filterArray.length > 0 ? filterArray[0].name : '';
            destSnd = this.$t('hotel-page.hotel-breadcrumb-hotel-sec', { destinationName: destinationText });
            destThird = this.$t('hotel-page.hotel-breadcrumb-hotel-third', { hotelname: hotelName, destinationName: destinationText });
          }
          break;
        }
        case 'home': {
          const params = this.$route.query;
          if (params.destination) {
            destCode = landingUrl.split('=')[1];
            const filterArray = this.destinationList.filter((item) => item.code === destCode);
            destinationText = filterArray.length > 0 ? filterArray[0].name : '';
            destSnd = this.$t('hotel-page.hotel-breadcrumb-dest-sec', { destinationName: destinationText });
            destThird = this.$t('hotel-page.hotel-breadcrumb-dest-third', { destinationName: destinationText });
            break;
          }

          if (params.chain) {
            destinationText = landingUrl.split('=')[1];
            destinationText = decodeURIComponent(destinationText);
            destSnd = this.$t('hotel-page.hotel-breadcrumb-chain-sec', { destinationName: destinationText });
            destThird = this.$t('hotel-page.hotel-breadcrumb-chain-third', { destinationName: destinationText });
            break;
          }

          break;
        }

        default: {
          destCode = '';
          break;
        }
      }

      return {
        hotelName,
        destSnd,
        destThird,
      };
    },
    dataDestination() {
      return {
        destinations: this.destinationList,
        hotDestinations: [],
      };
    },
  },
  watch: {
    lang() {
    },
    destination() {
      const dests = this.destination?.split(',') ?? [];
      dests.map((dest) => this.$store.dispatch('FETCH_DEALS_ATLANTIS', { dest }));
    },
  },
  methods: {
    async submitSearch(event) {
      event.preventDefault();
      if (this.isLoading) {
        this.$bvModal.msgBoxOk(this.$t('search-tab.is-loading-warning-message'), {
          title: this.$t('search-tab.is-loading-warning-title'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        return false;
      }
      if (this.type === 'mobile') {
        this.$emit('closeSearchMobile');
      }
      this.$root.$emit('setTimer');
      if (this.searchDisable) {
        return false;
      }
      const allSelected = this.selectedHotels[0].id === 0;
      const externalHotels = this.selectedHotels.filter((h) => (h.source !== 4));
      const hotelsCodes = allSelected ? [] : this.selectedHotels.map((item) => item.code);
      const sourceArray = allSelected ? [] : this.selectedHotels.map((item) => item.source);
      if (!allSelected) {
        hotelsCodes.push(...externalHotels.map((h) => h.atlantisCode));
        sourceArray.push(...externalHotels.map(() => 4));
      }
      const destCodes = !this.isMultipleSearchCase ? this.destination : this.destinations.join(',');
      // const destCodes = this.destination;
      let body = {
        city: destCodes,
        hotelCode: hotelsCodes.join(','),
        checkIn: this.fromDate.join(','),
        checkOut: this.toDate.join(','),
        adult: this.countAdult,
        child: this.countChild,
        infant: this.countInfant,
        lang: this.lang,
        includeFlight: null,
        suppliers: this.supplierListForSearch.join(','),
        returnLog: null,
      };
      if (!allSelected) body.sources = sourceArray.join(',');

      const { query } = this.$route;
      const { marketerId, partnerId } = this;
      if (marketerId) body = { ...body, marketerId };
      if (partnerId) body = { ...body, partnerId };
      if (Object.keys(body).every((key) => String(body[key]) === String(query[key]))) return false;

      this.$router.push({ path: '/search-result', query: body });
      if (this.isLanding) {
        setTimeout(() => {
          this.$router.go();
        }, 10);
      }
      return true;
    },
    callBreadcrumb() {
      return this.getBreadcrumb;
    },
    resetDate() {
      this.$store.dispatch('SET_SEARCH_ITEM', ['from', '']);
      this.$store.dispatch('SET_SEARCH_ITEM', ['to', '']);
    },
    destSelect(value) {
      this.destinationChanged = value;
    },
    hotelSelect(value) {
      this.hotelListChanged = value;
    },
    destChanged(dest) {
      if (!dest || !this.totalHotelList.data) return false;

      // this.resetDate();
      this.destination = dest.value;

      this.hotelList = this.totalHotelList.data.filter((item) => item.active && item.mainCityCode === this.destination);
      return true;
    },
    changeDestinations(dests) {
      if (!dests || !this.totalHotelList.data) return false;

      this.resetDate();
      this.destinations = dests.map((item) => item.value);
      this.destination = this.destinations.join(',');

      this.hotelList = this.totalHotelList.data.filter((item) => this.destinations.includes(item.mainCityCode));
      return true;
    },
    hotelChanged(hotels) {
      this.selectedHotels = hotels;// (hotels.length > 0) ? hotels.map((item) => item.value) : [];
    },
    chooseDate(date) {
      if (Array.isArray(date)) {
        this.fromDate = this.isMultipleDestinationSearch ? [date[0].from] : date.filter((d) => d.from).map((d) => d.from);
        this.toDate = this.isMultipleDestinationSearch ? [date[0].to] : date.filter((d) => d.to).map((d) => d.to);
      } else {
        this.fromDate = [date.from];
        this.toDate = [date.to];
      }
    },
    finalDateSelect(value) {
      this.dropdownShow = value;
    },
    changeCount({ adult, child, infant }) {
      this.countAdult = adult;
      this.countChild = child;
      this.countInfant = infant;
    },
  },
};
